import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import arrow_right from '../img/icons/arrow_right.svg';
import Footer from './footer';


const imgURL = process.env.PUBLIC_URL + '/img/large/';

export default class About extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="about-image-mobile-container">
          <img className="image-cover about-image-mobile" src={imgURL + 'img-61.jpg'} alt="" />
        </div>

        <div className="main-content main-content-offset about-mobile-offset">
          <div className="grid">
            <div className="grid__item width-6/12">
              <div className="vertical-center-parent">
                <div className="about-text-one">
                  <div className="about-text-one-title">
                    <h6>ABOUT US</h6>
                  </div>
                  <br className="desktop-only" />
                  <div>
                    <h1>Special moments, beautifully captured</h1>
                    <br />
                    <p>
                      We are husband and wife team Andrew and Emily, based in Auckland, NZ. We launched Landsdowne Photography to challenge the status quo of wedding photography in NZ and get better results by working more closely with couples.
                  </p>
                    <p>
                      Landsdowne is a passion project for us, each year we'll only take on a small number of bookings and pour our hearts into each one. We still have day jobs - Andrew is a visual effects compositor and Emily is a digital designer.
                  </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid__item width-6/12 desktop-only">
              <img className="about-image-desktop-one" src={imgURL + 'img-58.jpg'} alt="" />
            </div>
            {/* ----- */}
          </div>
          {/* end of  grid */}

          <div className="module-padding" />

          <div className="grid">
            <div className="grid__item width-6/12 desktop-only">
              <img className="about-image-desktop-two" src={imgURL + 'img-70.jpg'} alt="" />
            </div>
            <div className="grid__item width-1/12 desktop-only">
            </div>

            {/* mobile second image */}
            <div className="mobile-only">
              <img className="about-image-desktop-two" src={imgURL + 'img-70.jpg'} alt="" />
              <div style={{ height: '3em' }}>
              </div>
            </div>

            <div className="grid__item width-5/12">
              <div className="vertical-center-parent">
                <div className="about-text-three">
                  <div className="about-text-three">
                    <h1>The key is in the planning</h1>
                    <br />
                    <p>
                      We plan meticulously, then work in a manner that suits you personally. We adapt our shooting style to fit right in with your day, whether it be to mingle with your guests to capture candid moments or to set up for professional portraits, it's all up to you.
                </p>
                    <p>
                      For this, we like to meet you face-to-face and will get the ball rolling with questions about your venue, styling and direction preferences - it's comeletely fine if you have no idea about some of these, we'll walk you through everything in order to get the best possible images on the day.
                </p>
                    {/* <p>
            After your wedding we start the colour grading and retouching - we have each spent more than a decade honing our skills in this area and it allows you to get the very best from the images. It's all part of the crafting process that begins with our first meeting.
            </p> */}
                  </div>
                </div>
              </div>
            </div>

          </div>


          {/* end of  grid */}

          <div className="module-padding" />

          <div className="grid">
            <div className="grid__item width-2/12 desktop-only">
            </div>
            <div className="grid__item width-8/12">
              <div className="about-text-four">
                <h1 style={{ whiteSpace: 'normal' }}>Want to <span>fi</span>nd out if we're available for your big day?</h1>
              </div>
            </div>
            <div className="grid__item width-2/12 desktop-only">
            </div>
            {/* ----- */}
            <div className="grid__item width-10/12">
              <br />
              <div className="animated-arrow-link">
                <Link to="/contact">
                  <h5 style={{ textAlign: 'right' }}>Contact us <img className="arrow-right" src={arrow_right} alt="" /></h5>
                </Link>
              </div>
            </div>
          </div> {/* end of main grid */}
        </div>

        <Footer />
      </div>

    );
  }
}
