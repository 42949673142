import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
//import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { Link } from 'react-router-dom';
import arrow_right from '../img/icons/arrow_right.svg';

import Lightbox from '../components/lightbox/Lightbox';


const smallImgURL = process.env.PUBLIC_URL + '/img/small/';
const largeImgURL = process.env.PUBLIC_URL + '/img/large/';

const photos_small = [
  { src: smallImgURL + 'img-11.jpg', width: 7, height: 5 },
  { src: smallImgURL + 'img-49.jpg', width: 8, height: 5 },
  { src: smallImgURL + 'img-31.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-22.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-14.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-33.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-13.jpg', width: 7, height: 9 },
  { src: smallImgURL + 'img-27.jpg', width: 8, height: 5 },
  { src: smallImgURL + 'img-52.jpg', width: 7, height: 8 },
  { src: smallImgURL + 'img-21.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-45.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-46.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-10.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-28.jpg', width: 4, height: 7 },
  { src: smallImgURL + 'img-30.jpg', width: 4, height: 3 },
  { src: smallImgURL + 'img-15.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-16.jpg', width: 5, height: 3 },
  { src: smallImgURL + 'img-35.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-12.jpg', width: 9, height: 5 },
  { src: smallImgURL + 'img-43.jpg', width: 1, height: 1 },
  { src: smallImgURL + 'img-25.jpg', width: 4, height: 3 },
  { src: smallImgURL + 'img-38.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-36.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-24.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-17.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-54.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-39.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-40.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-47.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-56.jpg', width: 3, height: 4 },
  { src: smallImgURL + 'img-23.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-18.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-37.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-44.jpg', width: 1, height: 1 },
  { src: smallImgURL + 'img-48.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-41.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-50.jpg', width: 7, height: 9 },
  { src: smallImgURL + 'img-19.jpg', width: 3, height: 2 },
  { src: smallImgURL + 'img-51.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-53.jpg', width: 5, height: 7 },
  { src: smallImgURL + 'img-55.jpg', width: 4, height: 3 },
  { src: smallImgURL + 'img-32.jpg', width: 3, height: 4 },
  { src: smallImgURL + 'img-26.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-42.jpg', width: 2, height: 3 },
  { src: smallImgURL + 'img-29.jpg', width: 3, height: 2 },
];


// set large photo - swap out 'images_small' with 'images_large'
const photos_large = photos_small.map((item, index, array) => {
  let obj = {
    src: item.src.replace(smallImgURL, largeImgURL),
    width: item.width,
    height: item.height
  };
  return obj;

});




export default class Work_Previous extends Component {
  constructor() {
    super();
    this.state = {
      currentImage: 0,
      width: 0,
      height: 0,
      lightboxIsOpen: false,
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }


  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }

  galleryDisplay() {
    if (this.state.width < 534) {
      return (
        // mobile gallery
        <div>
          <Gallery
            direction={'column'}
            columns={1}
            photos={photos_small}
            margin={1}
            onClick={null}
          />
        </div>
      );
    } else {

      return (
        // desktop gallery
        <div>
          <Lightbox
            images={photos_large}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
            showImageCount={false}
          />

          <div className="grid">

            <div className="grid__item width-8/12 case-study-gallery-container">
              <Gallery
                direction={'row'}
                photos={photos_small}
                targetRowHeight={Math.min(Math.min(this.state.height, 768) / 2, 2500)}
                margin={1}
                onClick={this.openLightbox}
              />
            </div>
          </div>
          {/* end of  grid */}


        </div>
      );
    }
  }


  render() {


    return (
      <div className='gallery-content'>
        {/* <div style={{height: '2px'}}/> */}
        <div style={{ height: '2em' }} />



        {/* right hand side text */}
        <div className="case-study-content">
          <div className="main-content">
            <div className="grid">
              <div className="grid__item width-8/12">
              </div>
              <div className="grid__item width-4/12">
                <div className='case-study-text'>
                  <h6>NEW ZEALAND AND UK</h6>
                  <h1>Our previous weddings</h1>
                  <p>A selection of shots from previous weddings, often for family or friends of ours, stemming back to 2007</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: '2em' }} />
        </div>


        <div className="main-content">
          {/* gallery */}
          {this.galleryDisplay()}
        </div>


        <div className='inter-gallery-nav'>
          <div className="main-content">
            <div className="grid">
              <div className="grid__item width-8/12">
              </div>
              <div className="grid__item width-4/12">
                <div className='inter-gallery-nav-links'>
                  <div className='animated-arrow-link work-arrow-link inter-gallery-nav-link' id='left'>
                    <Link to="/work_hj">
                      <img className="arrow-right" src={arrow_right} alt="" /><h5>Previous</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* gap at bottom */}
        <div style={{ height: '6em' }} />
      </div>
    );
  }
}


