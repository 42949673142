import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from '../components/lightbox/Lightbox';
import Measure from 'react-measure';


export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      width: -1,

      username: '',
      password: '',
      loggedIn: false,
      loginError: false,
      id: '',
      names: '',
      location: '',
      date: '',
      photos_small: null,
      photos_large: null,
      data: null,
    };

    // lightbox binds
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);

    // login form binds
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.validateLogin = this.validateLogin.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // 
  //  Lightbox Functions
  //

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }


  // 
  //  Login form functions
  //

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    this.setState({
      loginError: false
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.validateLogin();
    }
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  renderLoginError() {
    if (this.state.loginError) {
      return (
        <p className="login-error-text">The supplied login credentials do not match our records</p>
      );
    }
    return;
  }

  validateLogin() {
    var raw_id = this.state.username + '_' + this.state.password;
    var id = btoa(raw_id); // encode to base64
    //const t = 'am9zc2ZhbWlseV9mYW1pbHkyOTcy';
    //console.log(atob(t));
    // test login: jackjill
    // mudbrick3897

    // remeber fetch is an asynchronous function so we need to set state 
    // within the function so we get visually 'logged in' as it finishes

    //fetch('http://127.0.0.1:5000/' + id) // for local testing
    fetch('/albums/' + id + '.txt')
      //    fetch('https://landsdowne.co.nz/albums/' + id + '.txt') 
      .then(response => response.text())
      .then(data => {
        this.setState({ data: data.split('\n') });

        // if there's less than 20 lines then we havent got an album
        if (this.state.data.length < 13) {
          this.setState({ loginError: true });
          return;
        }

        // if the first line is the 'html doctype' then we're getting a default response - its not an album
        if (this.state.data[0] === "<!DOCTYPE html>" || this.state.data[0] === "<html>" || this.state.data[0] === "<html>\r") {
          this.setState({ loginError: true });
          return;
        }

        var sml = [];
        var lrg = [];
        var i;
        // The first three lines are names/location/date,
        // 4th line onwards are for the images, which have 3 parts, seperated by a pipe '|'- url, width, height
        // hence starting from index 3
        for (i = 3; i < this.state.data.length; i++) {
          var lsplit = this.state.data[i].split('|');
          sml.push({
            src: lsplit[0] + '=w576-h432-no',
            width: lsplit[1],
            height: lsplit[2]
          });
          lrg.push({
            src: lsplit[0] + '=w2400-h1800-no',
            width: lsplit[1],
            height: lsplit[2]
          });
        }



        this.setState({
          photos_small: sml,
          photos_large: lrg,
          names: this.state.data[0],
          location: this.state.data[1],
          date: this.state.data[2],
          loggedIn: 'True',
          loginError: false
        });
      })
      .catch(error => {
        this.setState({ loginError: true });
        console.log('Fetch Error: ' + error);
        return;
      }
      );
  }



  // 
  //  Render Functions
  //

  renderLogin() {
    return (
      <div className="main-content main-content-offset">
        <div className="grid">
          <div className="grid__item width-12/12">
            <h1 className='hoz-centered-text'>
              Client Login
              </h1>

          </div>
          {/* -------- */}
          <div className="grid__item width-3/12">
          </div>
          <div className="grid__item width-6/12">
            <p className='hoz-centered-text'>
              Please enter your login details below. For assistance please contact us at info@landsdowne.co.nz
            </p>
          </div>
          <div className="grid__item width-3/12">
          </div>
          {/* -------- */}
          <div className="grid__item width-3/12">
          </div>
          <div className="grid__item width-6/12">
            {this.renderLoginError()}
            <p className="login-label">Username</p>
            <input type="text" name="username" className="login-input"
              value={this.state.user}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
            />
          </div>
          <div className="grid__item width-3/12">
          </div>
          {/* -------- */}
          <div className="grid__item width-3/12">
          </div>
          <div className="grid__item width-6/12">
            <p className="login-label">Password</p>
            <input type="text" name="password" className="login-input"
              value={this.state.pass}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
            />
          </div>
          <div className="grid__item width-3/12">
          </div>
          {/* -------- */}
          <div className="grid__item width-3/12">
          </div>
          <div className="grid__item width-6/12">
            <button onClick={this.validateLogin} disabled={!this.validateForm()} className='login-button'>Login</button>
          </div>
          <div className="grid__item width-3/12">
          </div>

        </div>
      </div>
    );
  }

  renderGallery() {
    return (
      <div className="client-gallery">
        <h1>{this.state.names}</h1>
        <p>{this.state.location} &nbsp; | &nbsp; {this.state.date}</p>

        <Measure
          bounds
          onResize={contentRect =>
            this.setState({ width: contentRect.bounds.width })
          }
        >
          {({ measureRef }) => {
            if (this.state.width < 1) {
              return <div ref={measureRef} />;
            }
            let columns = 1;
            if (this.state.width < 534) {
              // if on mobile return picture gallery without lightbox
              return (
                <div ref={measureRef}>
                  <Gallery
                    photos={this.state.photos_small}
                    columns={columns}
                    margin={1}
                    onClick={null}
                  />
                </div>
              );
            }
            if (this.state.width >= 534) {
              columns = 2;
            }
            if (this.state.width >= 1080) {
              columns = 3;
            }
            if (this.state.width >= 1824) {
              columns = 4;
            }
            return (
              <div ref={measureRef}>
                <Gallery
                  photos={this.state.photos_small}
                  columns={columns}
                  margin={1}
                  onClick={this.openLightbox}
                />
              </div>
            );
          }}
        </Measure>
        <Lightbox
          images={this.state.photos_large}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          showImageCount={false}
        />

      </div>
    );
  }

  render() {
    if (this.state.loggedIn) {
      return (this.renderGallery());
    }
    else {
      return (this.renderLogin());
    }
  }

}
