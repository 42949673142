import React, { Component } from 'react';

import Footer from './footer';

import dollarsign from '../img/icons/dollar.svg';

const imgURL = process.env.PUBLIC_URL + '/img/small/';


export default class Packages extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="main-content main-content-offset">
          <div className="grid">
            <div className="grid__item width-6/12">
              <div className="vertical-center-parent">
                <div className="packages-text-one">
                  <h6>PACKAGES</h6>
                  <br />
                  <br className="desktop-only" />
                  {/* <h1>Because no <br/>two weddings <br/>are the same</h1> */}
                  <h1>Flexible photography packages</h1>
                  <br />
                  <p>
                    All aspects of our packages can be tailored to your requirements, from additional portraits to stunning framed prints and albums.
                  </p>
                  <p>
                    We are based in Auckland but may well be able to travel to meet your requirements - please contact us for full details.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid__item width-6/12">
              <img className="packages-image-desktop desktop-only image-cover" src={imgURL + 'img-35.jpg'} alt="" />
            </div>
          </div> {/* end grid */}
          <div className="module-padding" />
        </div>



        <div className="packages-desktop-hr-container desktop-only">
          <hr />
        </div>

        <div className="main-content packages">
          <div className="grid">
            <div className="grid__item package width-4/12">
              <div className="package-content">
                <h2>Silver</h2>
              </div>
              <hr className="packages-mobile-hr" style={{ left: '0', width: '100%' }} />
              <div className="package-content">
                <ul className="package-ul package-content">
                  <li>Two photographers who can follow each side of the bridal party and capture extra moments and details</li>
                  <li>8 hours of photography on your wedding day, usually covering everything from preparations to your first dance</li>
                  <li>Fully retouched images delivered on a USB drive and in a password-protected online gallery</li>
                </ul>
                <div className="package-prices-mobile">
                  <h3 className="package-prices-mobile">From <img className="packages-dollar-sign" src={dollarsign} alt="" />2800</h3>
                </div>
              </div>
              <div className="package-padding-mobile" />
            </div>
            {/* ------- */}
            <div className="grid__item package width-4/12">
              <div className="package-content">
                <h2>Gold</h2>
              </div>
              <hr className="packages-mobile-hr" style={{ left: '0', width: '100%' }} />
              <div className="package-content">
                <ul className="package-ul">
                  <li>Everything in Silver <strong>plus:</strong></li>
                  <li>Five photos retouched and delivered digitally by the end of the following day for you to share with your family & friends and on social media</li>
                  <li>A premium Queensberry™ wedding album</li>
                </ul>
                <div className="package-prices-mobile">
                  <h3>From <img className="packages-dollar-sign" src={dollarsign} alt="" />3400</h3>
                </div>
              </div>
              <div className="package-padding-mobile" />
            </div>
            {/* ------- */}
            <div className="grid__item package width-4/12">
              <div className="package-content">
                <h2>Platinum</h2>
              </div>
              <hr className="packages-mobile-hr" style={{ left: '0', width: '100%' }} />
              <div className="package-content">
                <ul className="package-ul">
                  <li>The Gold package <strong>upgraded with:</strong></li>
                  <li>10 hours of photography, with two photographers</li>
                  <li>Ten photos retouched and delivered by the end of the following day</li>
                  <li>A premium Queensberry™ wedding album plus a stunning A2-size photo frame</li>
                </ul>
                <div className="package-prices-mobile">
                  <h3 className="package-prices-mobile">From <img className="packages-dollar-sign" src={dollarsign} alt="" />4100</h3>
                </div>
              </div>
              <div className="package-padding-mobile" />
            </div>
            <div className="grid__item package-prices-desktop width-4/12">
              <h3>From <img className="packages-dollar-sign" src={dollarsign} alt="" />2800</h3>
            </div>
            <div className="grid__item package-prices-desktop width-4/12">
              <h3>From <img className="packages-dollar-sign" src={dollarsign} alt="" />3400</h3>
            </div>
            <div className="grid__item package-prices-desktop width-4/12">
              <h3>From <img className="packages-dollar-sign" src={dollarsign} alt="" />4100</h3>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

