/*jshint esversion: 6 */


// vendor components
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// images
import ldLogo from './img/corporate/ld_logo_header.svg';
//import googleplus from './img/icons/google_plus.svg';
//import facebook from './img/icons/facebook.svg';
import close_cross from './img/icons/close.svg';

// containers
import Landing from './containers/landing';
import About from './containers/about';
import OurWork from './containers/our_work';
import Packages from './containers/packages';
import Contact from './containers/contact';
//import Terms from './containers/terms';
import Login from './containers/login';
import Work_Previous from './containers/work_previous';
import Work_KS from './containers/work_ks';
import Work_HJ from './containers/work_hj';


// styles 
import './css/normalize.css';
import './css/grid.css';
import './scss/main.css';
//import Footer from './components/lightbox/components/Footer';

// init google analytics
ReactGA.initialize('UA-143582262-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const linkStyle = {
  fontFamily: '"Montserrat", sans-serif',
  fontSize: '0.8em',
  fontWeight: '300',
  letterSpacing: '0.2em',
};

const linkStyleInactive = Object.assign({}, linkStyle, {
  textDecoration: 'none',
});

const linkStyleActive = Object.assign({}, linkStyle, {
  // textDecoration: 'underline',
  fontWeight: '600',
});




export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuVisible: false,
      windowWidth: 0,
      windowHeight: 0
    };
    this.toggleShowMobileMenu = this.toggleShowMobileMenu.bind(this);
    this.hideMobileMenu = this.hideMobileMenu.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    if (this.state.windowWidth > 768) {
      this.hideMobileMenu();
    }
  }

  toggleShowMobileMenu() {
    this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible });
  }

  hideMobileMenu() {
    if (this.state.mobileMenuVisible) {
      this.setState({ mobileMenuVisible: false });
    }
  }


  render() {
    var headerClass = classNames({
      'main-nav': true,
      'mobileMenuOpen': this.state.mobileMenuVisible
    });


    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div className="App">
          <Helmet>
            <link href="https://fonts.googleapis.com/css?family=Montserrat:300,600,900" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,700&display=swap" rel="stylesheet" />
            <link rel="stylesheet" type="text/css" href="normalize.css" />
            <link rel="stylesheet" type="text/css" href="grid.css" />
            <link rel="stylesheet" type="text/css" href="main.css" />

            {/* Icon/Favicon */}
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#bcbcbc" />
            <title>Landsdowne Photography | Auckland Wedding Photographers</title>
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
            <meta charset="utf-8" />
            <meta name="description" content="Wedding Photographers Auckland" />
            <meta name="keywords" content="wedding photographers auckland,wedding photographers auckland price,wedding photographer nz,wedding photographer cost nz,wedding photographer auckland,wedding photographer auckland prices,wedding photographer auckland,wedding photographer assistant,wedding photographer average price,the wedding photographer funny,a wedding photography studio,find a wedding photographer,choosing a wedding photographer checklist,what a wedding photographer should wear,an affordable wedding photographer,wedding photographer contract,wedding photographer deposit,wedding photographer instagram,wedding photographer email,wedding photographer hourly rate,wedding photographer list,wedding photographer fees,wedding photographer quotes,wedding photographer on a budget,list of wedding photographers,types of wedding photographers,cost of wedding photographer,wedding photographer rotorua,wedding photographer hamilton,wedding photographer waiuku,wedding photographer waitakere,wedding photographer north shore,wedding photographer waiheke,wedding photographer albany,wedding photographer questions,wedding photographer rates,wedding photographers,wedding photographer website,wedding photographer wanted,wedding photographer review,wedding photographer 1 hour,wedding photographer hourly,wedding photographer hourly rate,wedding photography 35mm,wedding photography 4k,best wedding photographers,top wedding photographers" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="theme-color" content="#000000" />
          </Helmet>

          <nav className={headerClass}>
            <div className="header-nav-grid grid">
              <div className="header-nav-item grid__item width-2/12">
                <div className="header-nav-item-container">
                  <NavLink to="/about" style={linkStyleInactive} activeStyle={linkStyleActive}>
                    ABOUT
                  </NavLink>
                </div>
              </div>
              <div className="header-nav-item grid__item width-2/12">
                <div className="header-nav-item-container">
                  <NavLink to="/our_work" style={linkStyleInactive} activeStyle={linkStyleActive}>
                    OUR WORK
                  </NavLink>
                </div>
              </div>
              <div className="header-nav-logo grid__item width-4/12">
                <NavLink to="/" style={linkStyleInactive} activeStyle={linkStyleActive}>
                  <img id="header-logo" src={ldLogo} alt="Home" height="35px" onClick={this.hideMobileMenu} />
                </NavLink>
              </div>
              <div className="header-nav-item grid__item width-2/12">
                <div className="header-nav-item-container">
                  <NavLink to="/packages" style={linkStyleInactive} activeStyle={linkStyleActive}>
                    PACKAGES
                  </NavLink>
                </div>
              </div>
              <div className="header-nav-item grid__item width-2/12">
                <div className="header-nav-item-container">
                  <NavLink to="/contact" style={linkStyleInactive} activeStyle={linkStyleActive}>
                    CONTACT
                  </NavLink>
                </div>
              </div>
            </div>
            {/* End of grid */}


            {/* Mobile/Hamburger Menu */}
            <div className="mobile-menu-items">
              <ul>
                <li>
                  <NavLink to="/about">
                    <h6 onClick={this.hideMobileMenu}>ABOUT</h6>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/our_work">
                    <h6 onClick={this.hideMobileMenu}>OUR WORK</h6>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/packages">
                    <h6 onClick={this.hideMobileMenu}>PACKAGES</h6>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact">
                    <h6 onClick={this.hideMobileMenu}>CONTACT</h6>
                  </NavLink>
                </li>
                {/* <li>
                   <NavLink to="/terms">
                    <h6 onClick={this.hideMobileMenu}>T & C'S</h6>
                  </NavLink> 
                </li>*/}
                <li>
                  <h6><img className="close-cross" src={close_cross} alt="" onClick={this.hideMobileMenu} /></h6>
                </li>
              </ul>
            </div>

            <div className="hamburger" onClick={this.toggleShowMobileMenu}>
              <span />
              <span />
              <span />
            </div>
          </nav>

          <main>
            <Route exact path="/" component={Landing} />
            <Route path="/about" component={About} />
            <Route path="/our_work" component={OurWork} />
            <Route path="/packages" component={Packages} />
            <Route path="/contact" component={Contact} />
            {/* <Route path="/terms" component={Terms}/> */}
            <Route path="/login" component={Login} />
            <Route path="/work_previous" component={Work_Previous} />
            <Route path="/work_hj" component={Work_HJ} />
            <Route path="/work_ks" component={Work_KS} />
          </main>


        </div>

      </Router>
    );
  }
}


