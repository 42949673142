import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Footer from './footer';

import arrow_right from '../img/icons/arrow_right.svg';

const imgURL = process.env.PUBLIC_URL + '/img/large/';

export default class OurWork extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="main-content main-content-offset about-mobile-offset">


          <Link to="/work_ks">
            <div className="grid work-grid-1px-gaps">
              <div className="grid__item width-8/12">
                <div className="work-title-text flexbox-vert">
                  <h1>Katherine <br/>& Sean</h1>
                  <div className="vertical-center-parent flex-grow">
                    <h6>PAIHIA, BAY OF ISLANDS</h6>
                  </div>
                </div>
                <img className="work-image-large" src={imgURL + 'img-90.jpg'} alt="" />
              </div>
              <div className="grid__item width-4/12 desktop-only">
                <div className="flexbox-vert full-height">
                  <img className="work-image-small" src={imgURL + 'img-80.jpg'} alt="" />

                  <div className='flex-grow work-right-text-area-small'>
                    <div className="animated-arrow-link work-arrow-link">
                      <h5>View gallery</h5>
                      <img className="arrow-right" src={arrow_right} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-only">
                <img className="work-image-small" src={imgURL + 'img-80.jpg'} alt="" />
                <div className="animated-arrow-link work-arrow-link">
                  <h5>View gallery<img className="arrow-right" src={arrow_right} alt="" /></h5>
                </div>
              </div>
            </div>
            {/* end of  grid */}
          </Link>



          <div className="module-padding" />
          <div className="mobile-only">
            <hr />
            <div style={{ height: '2.5em' }} />
          </div>



          <Link to="/work_hj">
            <div className="grid work-grid-1px-gaps">

              <div className="grid__item width-4/12 desktop-only">
                <div className="flexbox-vert full-height">
                  <img className="work-image-small" src={imgURL + 'img-110.jpg'} alt="" />

                  <div className='flex-grow work-right-text-area-small'>
                    <div className="animated-arrow-link work-arrow-link work-arrow-link-left">
                      <h5>View gallery</h5>
                      <img className="arrow-right" src={arrow_right} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid__item width-8/12">
                <div className="work-title-text work-title-text-right flexbox-vert">
                  <h1>Harriet <br/>& James</h1>
                  <div className="vertical-center-parent flex-grow">
                    <h6>NZ HIGH COUNTRY, QUEENSTOWN</h6>
                  </div>
                </div>
                <img className="work-image-large" src={imgURL + 'img-100.jpg'} alt="" />
              </div>


              <div className="mobile-only">
                <img className="work-image-small" src={imgURL + 'img-110.jpg'} alt="" />
                <div className="animated-arrow-link work-arrow-link work-arrow-link-left">
                  <h5>View gallery<img className="arrow-right" src={arrow_right} alt="" /></h5>
                </div>
              </div>
            </div>
            {/* end of  grid */}
          </Link>



          <div className="module-padding" />
          <div className="mobile-only">
            <hr />
            <div style={{ height: '2.5em' }} />
          </div>




          <Link to="/work_previous">
            <div className="grid work-grid-1px-gaps">
              <div className="grid__item width-8/12">
                <div className="work-title-text flexbox-vert">
                  <h1>Our Previous Weddings</h1>
                  <div className="vertical-center-parent flex-grow">
                    <h6>NEW ZEALAND & UK</h6>
                  </div>
                </div>
                <img className="work-image-large" src={imgURL + 'img-120.jpg'} alt="" />
              </div>
              <div className="grid__item width-4/12 desktop-only">
                <div className="flexbox-vert full-height">
                  <img className="work-image-small" src={imgURL + 'img-130.jpg'} alt="" />

                  <div className='flex-grow work-right-text-area-small'>
                    <div className="animated-arrow-link work-arrow-link">
                      <h5>View gallery</h5>
                      <img className="arrow-right" src={arrow_right} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-only">
                <img className="work-image-small" src={imgURL + 'img-130.jpg'} alt="" />
                <div className="animated-arrow-link work-arrow-link">
                  <h5>View gallery<img className="arrow-right" src={arrow_right} alt="" /></h5>
                </div>
              </div>
            </div>
            {/* end of  grid */}
          </Link>




        </div>

        <Footer />
      </div>




      //  The crafting doesn't stop there - we have each spent more than a decade honing our colour grading and retouching skills to get the very best from the images. 

      //  It's all part of the crafting process that begins with our first meeting.


      //  Attention to detail

      //  Knack  of making people laugh

      //  Pre-wedding or day-after sessions

      //  Enquire to see if you date is available


    );
  }
}
