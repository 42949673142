import React from 'react';

export default class SlideShow extends React.Component {
  constructor( props ) {
    super( props );
    
    //Set List of images source url
    this.images = props.imgs;
  
    //Initial state to keep track of current number of slide show
    this.state = {
      first: 0,
      current: 0,
      last: this.images.length - 1
    };
    
    this.initSlideShow = this.initSlideShow.bind(this);
    this.stopSlideShow = this.stopSlideShow.bind(this);
  }
  
  
  /*
    * nextImage shows next image in the node list.
    * Separated from animation for manual trigger
    */
  nextImage( ) {
    this.setState({ current: ( this.state.current < this.state.last) ? this.state.current + 1 : 0 });
  }
  
  /*
    * initSlideshow() is the trigger that does slide show
    */
  initSlideShow( ) {
    //console.log( "called");
    //Show next Image
    this.nextImage();
    
    //Trigger infinite loop every 5 seconds
    this.slideShowInstance = setTimeout( this.initSlideShow, 5000); 
  }
  
  /*
   * stopSlideShow is slide show destroyer.
   */
  stopSlideShow() {
    //Stop the slide show.
    clearTimeout( this.slideShowInstance );
  }
  
  renderImages () {
    let renderedImages = this.images.map( ( imgSrc, key ) => {
      return ( 
        <div className={ `slide fade ${(key !== this.state.current ) ? 'hide' : ''}` }>
          <img className="slide-img" src={ imgSrc } alt=""/>
        </div> 
      );
    });
    return renderedImages;
  }
  
  componentDidMount() {
    this.initSlideShow(); 
  }
  
  componentWillUnmount() {
    this.stopSlideShow();
  }

  render() {
    return (
      <div className="slideshow-wrapper">
        { this.renderImages() }
      </div>
    );
  }
       
}
        
