import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer';
import SlideShow from '../components/slideshow';

import arrow_right from '../img/icons/arrow_right.svg';


const imgURL = process.env.PUBLIC_URL + '/img/small/';
const sqImgURL = process.env.PUBLIC_URL + '/img/square/';

const slideshowImgURL = process.env.PUBLIC_URL + '/img/slideshow/';
const slideshowImages = [
  slideshowImgURL + 'slide-17.jpg',
  slideshowImgURL + 'slide-10.jpg',
  //slideshowImgURL + 'slide-12.jpg',
  slideshowImgURL + 'slide-11.jpg',
  slideshowImgURL + 'slide-09.jpg',
];

export default class Landing extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>

        <SlideShow imgs={Object.values(slideshowImages)} />

        {/* Main grid */}

        <div className="module-padding" />


        <div className="main-content">
          <div className="grid">
            <div className="grid__item width-2/12">
            </div>
            <div className="grid__item width-2/12">
              <div className="landing-text-one">
                <h1>Photography <br />designed around <br />you</h1>
              </div>
            </div>
            <div className="grid__item width-6/12">
              <div className="landing-text-two">
                <p>
                  Your wedding should be exactly the way you want it - and the photography should be no exception - so we tailor our approach to your requirements and style
                </p>
              </div>
            </div>
          </div>  {/* ----end of grid --- */}

          <div className="module-padding" />


          {/* --- desktop 6 images NB: must be square and preferably 576px ---- */}

          <div className="landing-text-3-our-work">
            <div>
              <h1>Our work</h1>
            </div>
          </div>

          <div className="grid landing-6images-grid">
            <div className="grid__item width-4/12">
              <div className='landing-square-image-container'>
                <img className="image-cover" src={sqImgURL + 'img-sq-10.jpg'} alt="" />
              </div>
            </div>
            <div className="grid__item width-4/12">
              <div className='landing-square-image-container'>
                <img className="image-cover" src={sqImgURL + 'img-sq-60.jpg'} alt="" />
              </div>
            </div>
            <div className="grid__item width-4/12">
              <div className='landing-square-image-container'>
                <img className="image-cover" src={sqImgURL + 'img-sq-30.jpg'} alt="" />
              </div>
            </div>
            <div className="grid__item width-4/12">
              <div className='landing-square-image-container'>
                <img className="image-cover" src={sqImgURL + 'img-sq-70.jpg'} alt="" />
              </div>
            </div>
            <div className="grid__item width-4/12">
              <div className='landing-square-image-container'>
                <img className="image-cover" src={sqImgURL + 'img-sq-20.jpg'} alt="" />
              </div>
            </div>
            <div className="grid__item width-4/12">
              <div className='landing-square-image-container'>
                <img className="image-cover" src={sqImgURL + 'img-sq-50.jpg'} alt="" />
              </div>
            </div>
          </div>


          {/* ------- */}
          <div className="landing-text-3-link">
            <div className="animated-arrow-link">
              <Link to="/our_work">
                <h5 style={{ textAlign: 'right' }}>See More </h5><img className="arrow-right" src={arrow_right} alt="" />
              </Link>
            </div>
          </div>  {/* ---end of grid ---- */}


          <div className="module-padding" />


          <div className="landing-quote-container grid">
            <div className="grid__item width-2/12">
              <hr />
            </div>
            <div className="grid__item width-1/12">
            </div>
            <div className="grid__item width-6/12">
              <div className='landing-quote-text'>
                <h4>
                  “I was close to tears when I saw the photos - they are marvelous! <br />
                    There are photos that I didn't remember happening
                    that completely surprised me and so many that melted my heart”
                    </h4>
                <h6>KATHERINE & SEAN</h6>
              </div>
            </div>
            <div className="grid__item width-1/12">
            </div>
            <div className="grid__item width-2/12">
              <hr id="second-hr" />
            </div>
          </div>

          <div className="module-padding" />


          <div className="mobile-only">
            <div>
              <img className="image-cover" src={imgURL + '../large/img-60.jpg'} alt="" />
            </div>
            <div className="landing-text-five" >
              <h1>A tailored package to suit your plans</h1>
              <p>
                Our <span>Silver</span>, <span>Gold</span> and <span>Platinum</span> packages offer great value from the outset. They're completely customisable to work in with your plans, so take a look to see which best suits you
              </p>
            </div>
            <div className="animated-arrow-link">
              <Link to="/packages">
                <h5 style={{ textAlign: 'right' }}>Packages </h5><img className="arrow-right" src={arrow_right} alt="" />
              </Link>

            </div>
          </div>


          <div className="grid desktop-only">
            <div className="grid__item width-5/12">
              <div className="vertical-center-parent">
                <div className="landing-text-four">
                  <h1>A tailored package to suit your plans</h1>
                  <p>
                    Our <span>Silver</span>, <span>Gold</span> and <span>Platinum</span> packages offer great value and they're completely customisable to work in with your plans, so take a look to see which best suits you
                  </p>
                  <div className="animated-arrow-link">
                    <Link to="/packages">
                      <h5 style={{ textAlign: 'right' }}>Packages</h5><img className="arrow-right" src={arrow_right} alt="" />
                    </Link>
                  </div>
                  <div className="module-padding mobile-only" />
                </div>
              </div>
            </div>
            <div className="grid__item desktop-only width-1/12">
            </div>
            <div className="grid__item desktop-only width-6/12">
              <img className="landing-image-one image-cover" src={imgURL + '../large/img-60.jpg'} alt="" />
            </div>
          </div>



          <div className="module-padding" />

          <div className="grid landing-text-eight">
            <div className="grid__item width-2/12">
            </div>
            <div className="grid__item text-wrap width-8/12">
              <h1 style={{ whiteSpace: 'normal' }}>Want to <span>fi</span>nd out if we're available for your big day?</h1>
            </div>
            <div className="grid__item width-2/12">
            </div>
            {/* ------- */}
            <div className="grid__item width-2/12">
            </div>
            <div className="grid__item width-8/12">
              <br />
              <div className="animated-arrow-link">
                <Link to="/contact">
                  <h5 style={{ textAlign: 'right' }}>Contact us <img className="arrow-right" src={arrow_right} alt="" /></h5>
                </Link>
              </div>
            </div>
          </div> {/* --- end of grid--- */}
        </div >

        <Footer />
      </div >
    );

  }
}