// ==============================
// THEME
// ==============================

const theme = {};


// container
theme.container = {
  background: 'rgba(0, 0, 0, 0.85)',
  gutter: {
    horizontal: 30,
    vertical: 50,
  },
  zIndex: 2001,
};

// header
theme.header = {
  height: 10,
};
theme.close = {
  fill: ['rgba(200, 200, 200, 0.85)'],
};

// footer
theme.footer = {
  color: 'white',
  count: {
    color: 'rgba(200, 200, 200, 0.85)',
    fontSize: '0.85em',
  },
  height: 2,
  gutter: {
    horizontal: 0,
    vertical: 5,
  },
};

// thumbnails
theme.thumbnail = {
  activeBorderColor: 'white',
  size: 50,
  gutter: 2,
};

// arrow
theme.arrow = {
  background: 'none',
  fill: 'rgba(200, 200, 200, 0.85)',
  height: 120,
};


export default theme;
