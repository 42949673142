
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import instagram_icon from '../img/icons/instagram.svg';



export default class Footer extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (

            <footer>
                <div className="footer-link-container">
                    {/* <Link to="/contact"><h6 onClick={this.hideMobileMenu}>CONTACT</h6></Link> */}
                </div>

                <div className="footer-link-container" onClick={this.hideMobileMenu}>
                    <Link to="/terms">
                        {/* <h6 >TERMS AND CONDITIONS</h6> */}
                    </Link>
                </div>
                <div className="footer-bottom">
                    <div className="social-icons">
                        <a href="https://www.instagram.com/landsdowne_photography" target="_blank" rel="noopener noreferrer">
                            <img className="social-icon" src={instagram_icon} alt="Landsdowne Photography on Instagram" />
                        </a>
                    </div>
                    <h6 style={{ fontSize: '0.61em', fontWeight: '100', letterSpacing: '0.1em' }}>© 2021 LANDSDOWNE PHOTOGRAPHY LTD</h6>
                </div>
            </footer>

        )
    }
}






