import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Footer from './footer';

import arrow_right from '../img/icons/arrow_right.svg';
import calendar_icon from '../img/icons/calendar.svg';


export default class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    startDate: new Date()
  };
 
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  render() {

    return (
      <div>
        <div className="main-content main-content-offset contact-form">

          <form method="POST" action="https://formspree.io/info@landsdowne.co.nz">
            <div className="grid">
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="contact-text-one">
                  <h6>CONTACT US</h6>
                  <h1>For questions <br />& availability<br /> please contact us:</h1>
                  <p>Please fill out the form below, or feel free to email us <a href="mailto:info@landsdowne.co.nz">info@landsdowne.co.nz</a> and we'll get back to you as soon as possible</p>
                </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="contact-form-field">
                  <p>What are your and your partners' names?</p>
                  <input type="text" name="name" placeholder="" className="contact-input" />
                </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
              {/* ----- */}
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="contact-form-field">
                  <p>What is your email address?</p>
                  <input type="email" name="email" placeholder="" className="contact-input" />
                </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
              {/* ----- */}
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="contact-form-field">
                  <p>What is the best number to contact you on? (Optional)</p>
                  <input type="text" name="phone" placeholder="" className="contact-input" />
                </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
              {/* ----- */}
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="contact-form-field">
                  <p>When is the big day? (Optional)</p>
                  <img className="contact-calendar-icon" src={calendar_icon} alt="" />
                  <DatePicker 
                    name="date" 
                    selected={this.state.startDate} 
                    onChange={this.handleChange} 
                    dateFormat="dd / MM / yy"
                  />
                  </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
              {/* ----- */}
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="contact-form-field">
                  <p>Additional information</p>
                  <textarea name="message" placeholder="" className="contact-input contact-message"></textarea>
                </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
              {/* ----- */}
              <div className="grid__item width-2/12">
              </div>
              <div className="grid__item width-8/12">
                <div className="mobile-only">
                  <br />
                </div>
                <div style={{ float: 'right' }}>
                  <div className="animated-arrow-link">
                    <input className="contact-submit" type="submit" value="Submit" style={{ textDecoration: 'none' }} />
                    <img className="arrow-right" src={arrow_right} alt="" />
                  </div>
                </div>
              </div>
              <div className="grid__item width-2/12">
              </div>
            </div> {/* -- end of grid -- */}
          </form>

        </div> {/* -- end of main content -- */}
        <Footer />
      </div>
    );
  }
}
